import React from "react";
import loadable from "@loadable/component";

import Title from "../text/title";

import DividerPointedTop from "../divider/divider-pointed-top";
import DividerPointedBottom from "../divider/divider-pointed-bottom";

const CartogramHero = loadable(() => import("./cartogram/hero"));
const DyslexiaAiHero = loadable(() => import("./dyslexia/hero"));

export default function Apps() {
  return (
    <section id="apps" className="flex-col bg-invisible text-center">
      <div className="relative">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#1A1B21" fill-opacity="1" d="M0,64L48,69.3C96,75,192,85,288,85.3C384,85,480,75,576,80C672,85,768,107,864,122.7C960,139,1056,149,1152,144C1248,139,1344,117,1392,106.7L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
      </svg>
      </div>

      <DyslexiaAiHero />
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#323232" fill-opacity="1" d="M0,96L48,106.7C96,117,192,139,288,133.3C384,128,480,96,576,85.3C672,75,768,85,864,122.7C960,160,1056,224,1152,245.3C1248,267,1344,245,1392,234.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      <CartogramHero />
    </section>
  );
}
